@import 'variables';

:root {
  // used for panels, accordions, cards, ...
  --ta-panel-highlighted-color: #F8F9FA;
  --ta-panel-highlighted-border-color:  #DFDFE0;
  --ta-panel-highlighted-for-home-color: #e6eef2;
  --ta-panel-highlighted-border-for-home-color: #E6E6E7;

  --ta-table-row-odd-color: #F2F2F2;
}

html,
body {
  position: fixed!important;
  top: 0; 
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  touch-action: pan-y;

  background: var(--ta-body-background-bottom-color);
  background: linear-gradient(
    to bottom,
    var(--ta-body-background-top-color) 0,
    var(--ta-body-background-bottom-color) 100%);
}

.bg-header {
  background-color: var(--ta-panel-header-background-color);
}

.no-li > ul > li {
  list-style-type: none;
}

.panel-header-text-color {
  color: var(--ta-panel-header-text-color)!important;
}

.ta-panel-padding {
  padding: var(--ta-panel-body-padding);
}

.ta-panel-body {
  position: relative;
  color: var(--ta-text-color);
  padding: var(--ta-panel-body-padding);
  background: var(--ta-panel-body-background-color);
  border-width: 1px;
  border-style: solid;
  border-color: var(--ta-panel-border-color);
  min-height: 200px;
}

.ta-catalog-background-color {
  background-color: var(--ta-body-background-bottom-color);
}

.ta-bg-light-color {
  background-color: var(--ta-light-color);
}

.ta-text-color {
  color: var(--ta-text-color)!important;
}

body a {
  color: var(--ta-text-color);
  text-decoration: none;

  &:hover {
    color: var(--ta-primary-color);
    text-decoration: none;
  }

  &.dropdown-item:active:hover {
    color: var(--ta-primary-text-hover-color);
  }
}

a.grabbable {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }  
}

body {
  // breakpoint arrangement classes
  &.ta-l-1024 {
    .border-l-1024-0 {
      border: none!important;
    }
  }
}

.visible-from-768  { @media (max-width: 767px)  { display: none !important; } }    
.visible-from-1024 { @media (max-width: 1023px) { display: none !important; } }
.visible-from-1200 { @media (max-width: 1199px) { display: none !important; } }
.visible-from-1400 { @media (max-width: 1399px) { display: none !important; } }
.visible-from-1600 { @media (max-width: 1599px) { display: none !important; } }

.hidden-from-768   { @media (min-width: 768px)  { display: none !important; } }
.hidden-from-1024  { @media (min-width: 1024px) { display: none !important; } }


.html-panel {
  &.pre-wrap {
    white-space: pre-wrap;
  }

  .title {
    font-weight: bold;
    font-size: 16px;
  }

  a {
    text-decoration: underline;
  }
}
*:-webkit-full-screen {
  width: 100%;
  height: 100%;
}
