.loading-page {
  position: absolute;
  background: var(--ta-light-color);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  p {
    margin: 0;
    position: absolute;
    font-size: 2em;
    color: var(--ta-dark-color);
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: var(--ta-text-color);
  }
}
