.simplebar-wrapper {
  & > .simplebar-mask {
    & > .simplebar-offset {
      & > .simplebar-content-wrapper {
        position: static;
        min-height: 100%;

        & > .simplebar-content {
          min-height: 100%;
        }
      }
    }
  }
}

/* iOS fix to avoid blinking in elements with positions absolute */
body.ios {
  .no-smooth-scrolling {
    -webkit-overflow-scrolling: auto!important;
    
    & > .simplebar-wrapper {
      & > .simplebar-mask {
        & > .simplebar-offset {
          & > .simplebar-content-wrapper {
            -webkit-overflow-scrolling: auto!important;
          }
        }
      }
    }
  }
}

.simplebar-track {
  &.simplebar-vertical {
    width: var(--ta-scrollbar-size);
  }

  &.simplebar-horizontal {
    height: var(--ta-scrollbar-size);
  }

  .simplebar-scrollbar {
    &:before {
      background: var(--ta-scrollbar-track-piece-color);
      border-radius: var(--ta-scrollbar-track-piece-border-radious);
    }

    &.simplebar-hover:before,
    &.simplebar-visible:before {
      opacity: 1;
    }
  }
}